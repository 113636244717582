<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12"></v-col>
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">关于FPSHelper</h1>
        <p class="subheading font-weight-regular">About FPSHelper</p>
      </v-col>

      <v-col cols="12">
        <v-row justify="center">
          <v-col cols="12" sm="8">
            <v-card outlined>
              <v-card-title class="display-1">基本信息</v-card-title>
              <v-card-text class="title text-left">
                &nbsp;&nbsp;&nbsp;&nbsp;本网站旨在为广大FPS游戏爱好者免费提供各类实用工具，首先推出的是鼠标灵敏度转换工具，此后还会推出一系列迎合玩家需求的其他工具。对本网站有任何意见或建议的朋友，欢迎通过下方提供的方式联系我们。
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="8">
            <v-card outlined>
              <v-card-title class="display-1">联系我们</v-card-title>
              <v-card-text class="title text-left">
                <p>QQ：1228133346</p>
                <p>邮箱：1228133346@qq.com</p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="8">
            <v-card outlined>
              <v-card-title class="display-1">支持我们</v-card-title>
              <v-card-text class="title text-left">
                <a href="https://afdian.net/a/fpshelper"
                  >https://afdian.net/a/fpshelper</a
                >
                <p />
                <img src="../../public/afd.jpg" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  metaInfo: {
    title: "关于FPSHelper", // set a title
    meta: [
      {
        // set meta
        name: "keyWords",
        content: "关于FPSHelper",
      },
    ],
    link: [
      {
        // set link
        rel: "asstes",
        href: "http://fpshelper.com/about",
      },
    ],
  },
};
</script> 
